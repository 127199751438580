import { TranslationVersion } from '~/editor/translation/translation.interfaces'
import { Database, Json } from '~/types/supabase'
import { Point } from 'fabric'
import { Permission } from '~/types/database/series'
import { Count } from '~/types/database/tools'

const defaultStartPoint = new Point(0, 0)
const defaultEndPoint = new Point(100, 100)

const jsonToPoint = (json: Json) => {
  const object = typeof json === 'string' ? JSON.parse(json) : { x: 0, y: 0 }
  return new Point(object.x, object.y)
}

const pointToJson = (point: Point) => {
  const json = JSON.stringify({
    x: point.x,
    y: point.y
  })
  return json
}

const dbRowToTranslationVersion = (row: Database['public']['Tables']['translations']['Row']) => {
  const version: TranslationVersion = {
    id: row.entry_id,
    isCorrection: Boolean(row.is_correction),
    isOnomatopoeia: Boolean(row.is_onomatopoeia),
    startPoint: row.start_point ? jsonToPoint(row.start_point) : null,
    endPoint: row.end_point ? jsonToPoint(row.end_point) : null,
    date: row.created_at,
    translatedText: row.translated_text ?? '',
    originalText: row.original_text ?? '',
    userId: row.user_id,
    pageId: row.page_id ?? 0
  }
  return version
}

// Simplifies Supabase count filtering
// until they allow using count value in .eq()
type Row<T> = T & Count<'deleted'>
const filterDeletedEntries = <T>(rows: Row<T>[]) => {
  return rows.filter(entry => entry.deleted[0].count === 0)
}

const getUserIdsForPermissionType = (permissions: Database['public']['Tables']['series_permissions']['Row'][], type: Permission['permission']) => {
  return permissions.filter(entry => entry.permission === type).map(entry => entry.user_id)
}

export {
  defaultStartPoint,
  defaultEndPoint,
  jsonToPoint,
  pointToJson,
  filterDeletedEntries,
  dbRowToTranslationVersion,
  getUserIdsForPermissionType
}