import { supabase } from '~/services/supabase'
import { reportError } from '~/services/errors'
import { Database, TablesUpdate } from '~/types/supabase'
import { CreateChapterPayload, DeleteChapterPayload, UpdateChapterPayload } from '~/types/database/chapter'
import { safeFileName, upload } from '~/services/database/file-storage'
import { filterDeletedEntries } from '~/helpers/database-conversions'
import { ChapterRowProps } from '~/components/chapter-row/chapter-row.interfaces'
import { createPages, updatePages } from '~/services/database/pages'

const createChapter = async (payload: CreateChapterPayload) => {
  const row: Database['public']['Tables']['chapters']['Insert'] = {
    index: parseFloat(payload.index),
    title: payload.title ?? null,
    series_id: payload.comicId,
    progress: payload.progress
  }

  // Create Chapter Entry
  const { data: chapterData, error: chapterError } = await supabase
    .from('chapters')
    .insert([row])
    .select()
  if (chapterError) {
    reportError(chapterError)
    throw chapterError
  }
  const chapterId = chapterData[0].id

  // Upload original pages
  await createPages({
    chapterId,
    files: payload.pagesOriginal,
    type: 'original'
  })

  // Upload clean pages if any
  if(payload.pagesClean){
    await createPages({
      chapterId,
      files: payload.pagesClean,
      type: 'clean'
    })
  }
  
  return chapterId
}

const updateChapter = async ({ id, assetsFile, index, pagesClean, pagesOriginal, progress, title }: UpdateChapterPayload) => {
  const row: TablesUpdate<'chapters'> = {}
  if (index) row.index = parseFloat(index)
  if (title) row.title = title ?? null
  if (progress) row.progress = progress

  if(assetsFile){
    const path = `${id}/${safeFileName(assetsFile.name)}`
    const file = assetsFile

    const assetPath = await upload({
      path,
      file,
      bucket: 'assets'
    })
    row.assets_file = assetPath
  }

  if(pagesOriginal && pagesOriginal.length > 0){
    await updatePages({
      chapterId: id,
      type: 'original',
      files: pagesOriginal
    })
  }

  if (pagesClean && pagesClean.length > 0){
    await updatePages({
      chapterId: id,
      type: 'clean',
      files: pagesClean
    })
  }

  const { data, error } = await supabase
    .from('chapters')
    .update(row)
    .eq('id', id)
    .select()
  if (error) {
    reportError(error)
    throw error
  }

  return data
}

const deleteChapter = async (payload: DeleteChapterPayload) => {
  const { data, error } = await supabase
    .from('chapters_deletions')
    .insert({
      chapter_id: payload.id
    })
  if (error) {
    reportError(error)
    throw error
  }
  return data
}

const getChapter = async (id: Database['public']['Tables']['chapters']['Row']['id']) => {
  const { data, error } = await supabase
    .from('chapters')
    .select()
    .eq('id', id)
    .single()
  if (error) {
    reportError(error)
    throw error
  }
  return data
}

const getChapters = async (comicId: Database['public']['Tables']['series']['Row']['id']) => {
  const { data, error } = await supabase
    .from('chapters')
    .select('*, deleted:chapters_deletions(count)')
    .eq('series_id', comicId)
    .returns<ChapterRowProps['row'][]>()
  if (error) {
    reportError(error)
  }
  const filteredByNotDeleted = data ? filterDeletedEntries(data) : []
  const sortedByChapterIndex = filteredByNotDeleted.sort((a, b) => a.index - b.index).reverse()
  return sortedByChapterIndex
}

export {
  createChapter,
  updateChapter,
  deleteChapter,
  getChapter,
  getChapters
}