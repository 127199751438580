import { v4 as uuidv4 } from 'uuid'
import { reportError } from '~/services/errors'
import { supabase } from '~/services/supabase'
import { FileReadPayload, FileUploadPayload } from '~/types/database/files'

const safeFileName = (filename: string) => {
  const timestamp = Date.now()
  const extension = filename.split('.').pop()
  return `${timestamp}_${uuidv4()}.${extension}`
}

const upload = async({ path, file, bucket }: FileUploadPayload) => {
  const { data: uploadData, error: uploadError } = await supabase
    .storage
    .from(bucket)
    .upload(path.replace(' ', '_'), file)
  const storagePath = uploadData?.path

  if (storagePath) {
    return storagePath
  } else {
    if (uploadError) reportError(uploadError)
    throw uploadError
  }
}

const getFileURL = async ({ path, bucket, options }: FileReadPayload) => {
  const { data } = await supabase
    .storage
    .from(bucket)
    .getPublicUrl(path, options)
  return data.publicUrl
}

export { safeFileName, upload, getFileURL }