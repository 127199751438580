import { safeFileName, upload } from '~/services/database/file-storage'
import { reportError } from '~/services/errors'
import { reorderPagesByFilename } from '~/services/import/images'
import { supabase } from '~/services/supabase'
import { CreatePagesPayload, InsertPagesPayload, PageEntry } from '~/types/database/pages'
import { Tables, TablesInsert, TablesUpdate } from '~/types/supabase'

const getPages = async (chapterId: Tables<'chapters'>['id']) => {
  const { data, error } = await supabase
    .from('pages')
    .select('*')
    .eq('chapter_id', chapterId)
    .eq('deleted', false)
  if (error) {
    reportError(error)
  }
  const pages = data ?? []
  return pages
}

const createPages = async ({ chapterId, files, type }: CreatePagesPayload) => {
  const { data: series } = await supabase
    .from('chapters')
    .select('series_id')
    .eq('id', chapterId)
    .single()

  const createdPages: Tables<'pages'>[] = []

  if (series?.series_id){
    // Upload pages
    const uploadTasks = reorderPagesByFilename(files).map(async (file) => {
      const path = `${series.series_id}/${chapterId}/${safeFileName(file.name)}`
      return await upload({
        file,
        path,
        bucket: 'pages'
      })
    })
    const paths: string[] = await Promise.all(uploadTasks)

    // Create Pages Entry
    const pages: PageEntry[] = paths.map((path, index) => ({
      index,
      path
    }))
    const rows = await insertPages({
      chapterId,
      type,
      pages
    })
    createdPages.push(...rows)
  }
  
  return createdPages
}

const insertPages = async ({ chapterId, pages, type }: InsertPagesPayload) => {
  const pagesRows: TablesInsert<'pages'>[] = pages.map(({ index, path }) => ({
    index,
    type: type,
    chapter_id: chapterId,
    file: path
  }))

  const { error: pagesError, data } = await supabase
    .from('pages')
    .insert(pagesRows)
    .select()
  if (pagesError) {
    reportError(pagesError)
    throw pagesError
  }

  return data
}

const updateSinglePage = async (id: Tables<'pages'>['id'], entry: TablesUpdate<'pages'>) => {
  const { error } = await supabase
    .from('pages')
    .update(entry)
    .eq('id', id)
  if (error) throw error
}

const updatePages = async ({ chapterId, files, type }: CreatePagesPayload) => {
  const { data: series } = await supabase
    .from('chapters')
    .select('series_id')
    .eq('id', chapterId)
    .single()

  const { data: existingPages } = await supabase
    .from('pages')
    .select('*')
    .order('index')
    .eq('chapter_id', chapterId)
    .eq('type', type)
  
  if (series?.series_id && existingPages){
    // Upload pages
    const uploadTasks = files.map(async (file) => {
      const path = `${series.series_id}/${chapterId}/${safeFileName(file.name)}`
      return await upload({
        file,
        path,
        bucket: 'pages'
      })
    })
    const pagesPaths: string[] = await Promise.all(uploadTasks)

    // Update existing pages rows
    const existingPagesPaths = pagesPaths.slice(0, existingPages.length)
    const updates = existingPagesPaths.map((path, index) => {
      return updateSinglePage(existingPages[index].id, {
        index: index + 1,
        type: type,
        chapter_id: chapterId,
        file: path
      })
    })
    
    // Insert new rows if user added more pages than current list
    const newPagesPaths = pagesPaths.slice(existingPages.length, pagesPaths.length)
    const newPages: PageEntry[] = newPagesPaths.map((path, index) => ({
      index: existingPages.length + index + 1,
      path
    }))
    const inserts = insertPages({
      type: type,
      chapterId: chapterId,
      pages: newPages
    })

    console.log({
      pagesPaths, 
      existingPages,
      existingPagesPaths, 
      newPages, 
      newPagesPaths
    })

    await Promise.all([...updates, inserts])
  }else{
    throw new Error('invalidSeriesId')
  }
}

export {
  getPages,
  createPages,
  insertPages,
  updatePages
}